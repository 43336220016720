<template>
  <div>
    <h1
        class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
        style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
      >
      <h6 class="h2 text-white d-inline-block mb-0"> Pengumuman</h6>
    </h1>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="6" cols="7" >
        </b-col>
      </b-row>
    </base-header>

    <b-container v-if="isLoading">
        <h2 class="text-center" style="margin-top: 30vh;">Loading...</h2>
    </b-container>

    <b-container fluid class="mt--6" v-if="!isLoading">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <b-row>
                <b-col lg="6" cols="7">
                  <!-- <h6 class="surtitle">Overview</h6>
                  <h5 class="h3 mb-0">Total sales</h5> -->
                </b-col>
  
                <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
                </b-col>
              </b-row>
            </template>

           <section id="mytable">
            <el-table
              :data="listData"
              row-key="id"
              header-row-class-name="thead-light"
            >

              <el-table-column label="No" type="index" width="70" />


              <el-table-column min-width="220px"  label="Tanggal Terbit">
                <div slot-scope="action">
                  {{ action.row.publish_date }}
                </div>
              </el-table-column>

              <el-table-column min-width="550px" label="Keterangan">
                <div slot-scope="action">
                  <span v-html="action.row.content"> </span>
                </div>
              </el-table-column>


              <el-table-column min-width="250px"  label="Aksi">
                <div slot-scope="action">
                    <base-button
                    @click="view(action.row)"
                    type="secondary"
                    size="sm"
                    icon
                    >
                    RINCIAN
                    </base-button>
                    <!-- edit -->
                </div>
              </el-table-column>
            </el-table>

            <div class="py-3">

                <el-select
                  class="select-primary pagination-select mr-3"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                  @change="changeSizePage()"
                  >
                  <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>

                <small class="card-category">
                Showing {{  1 }} to {{ listData.length }} of {{ pagination.total }} entries
                </small>
            </div>

            <div style="display: flex;justify-content: flex-end;">
              <base-pagination
                  class="pagination-no-border"
                  :current="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="pagination.total"
                  @change="paginationChanged($event)"
              >
              </base-pagination>
            </div>
           </section>

          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- Modal untuk View -->
    <Modal
      :show="showFormModalView"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showFormModalView = false"
      size="xl"
    >
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <h3>Pengumuman Detail</h3>
          <b-row>
            <b-col xl="12">
              <div class="container">
                <b-card>
                  <template v-slot:header>
                    <div class="card-body">
                      <!-- <div class="row mb-4">
                        <div class="col-lg-2">
                          <strong>Nama</strong>
                        </div>
                        <div class="col-lg-10">
                          <span>{{ updateTitle }}</span>
                        </div>
                      </div> -->

                      <div class="row mb-4">
                        <div class="col-lg-2">
                          <strong>Publis Tanggal</strong>
                        </div>
                        <div class="col-lg-10">
                          <span>{{ tanggalUpdate }}</span>
                        </div>
                      </div>
              
                      <div class="row mb-4">
                        <div class="col-lg-2">
                          <strong>Pengumuman</strong>
                        </div>
                        <div class="col-lg-10">
                          <span v-html="updateContent"> </span>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-2">
                          <strong>Lampiran</strong>
                        </div>
                        <div class="col-lg-10">
                          <span>
                            <a :href="`https://${updateFile}`" target="blank" class="text-blue">Lihat Lampiran</a>
                          </span>
                        </div>
                      </div>

                      
                    </div>
                  </template>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="showFormModalView = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

  </div>
</template>
<script>
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import BaseHeader from '@/components/BaseHeader';
  import API from "@/api/base_url.js";
  import Modal from "@/components/Modal.vue";
  import { Select, Option, Table, TableColumn } from "element-ui";
  import moment from 'moment'
  


  export default {
    components: {
    Modal,
    BaseHeader,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    },
    mounted() {
      this.getData()
    },
    data() {
      return {
        updateTitle:"",
        updateContent:"",
        updateFile:"",
        tanggalUpdate: "",
        pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 1
        },
       isLoading:true,
       showFormModal: false,
       showFormModalView: false,
       listData: []
      };
    },
    methods: {
      view(row) {
        this.showFormModalView = true
        this.updateTitle = row.title
        this.updateContent = row.content
        this.updateFile = row.attachment
        this.tanggalUpdate = row.publish_date
        this.idWillUpdate = row.id
      },
      changeSizePage() {
        this.getData()
      },
      paginationChanged(page) {
        this.pagination.currentPage = page;
        this.getData()
      },
      addAnouncement() {
        console.log('add Announcement');
        this.showFormModal = true
      },
      getData() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
        API.get(`announcements?page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}` , {headers})
          .then(({data}) => {
            if (data.data == null) {
              this.listData = []
            } else {
              this.listData = data.data
              this.pagination.total = data.meta.pagination.total_items
            }
              this.isLoading = false
          })
          .catch((err) => {
            Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
            this.isLoading = false
        })
      },
      formatDate(timestamp) {
      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY - hh:mm:ss');
      return humanDate;
    },
    },
  };
</script>
<style></style>
